<template>
    <div class="datatable-section p-2">
        <vpanel-unassigned-bio-list />
        <recycle-unassigned-bio-modal />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BioIndex from '../../../../Portal/Custom/Site/views/BioIndex.vue';
export default {
    components: { BioIndex },
    name: "ObituaryUnassignedBioIndex",
    created() {
    },
};
</script>
