<template>
    <div class="bio-form flex-grow-1" :class="{'vp': vp}">
        <div class="top-bar">
            <button
                type="button"
                class="btn btn-primary btn-lg flex-shrink-0"
                @click="download()"
                v-if="hasBio && activeRecord.role_name=='organizer'"
            >Download Bio PDF</button>
            <BioNav />
        </div>

        <form
            novalidate
            @submit.prevent="save()"
            id="add-modify-funeral-form"
            :class="vp ? 'm-0 overflow-hidden h-100' : ''"
        >
            <div :class="vp ? 'flex-grow-1 overflow-auto' : ''">
                <div
                    v-show="errorMessage"
                    v-html="errorMessage"
                    class="alert alert-danger form-errors alert-dismissible"
                ></div>

                <div
                    v-show="successMessage"
                    class="alert alert-success alert-dismissible"
                    v-html="successMessage"
                ></div>

                <BiographyForm v-if="viewBio" />

                <p v-else>
                    You do not have access to this information please contact the funeral organizer for more information.
                </p>
            </div>

            <button
                v-if="editBio"
                class="btn btn-primary btn-lg align-self-end flex-shrink-0"
            >Save Biographical Info</button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'BioIndex',
    data() {
        return {
            showBio: false,
            vp: false,
            funeral_id: 0,
            funeral_name: '',
            id: 0,
            user_id: 0,
        };
    },
    computed: {
        ...mapState('siteFuneral/addModify', [
            'activeRecord',
            'isFormProcessing',
            'didSaveDraft',
            'family_info',
            'errorMessage',
            'successMessage',
        ]),
        ...mapState('siteFuneral/list', [
            'branches',
        ]),
        ...mapState(['funerals', 'userData']),
        hasBio() {
            return this.activeRecord?.funeral_bio?.biography_info_fe ? Object.keys(this.activeRecord.funeral_bio.biography_info_fe).length : false;
        },
    },
    methods: {
        ...mapActions('siteFuneral/list', ['selectFuneral']),
        ...mapActions('siteFuneral/addModify', ['submitBioForm', 'submitBioFormUnassigned', 'downloadPdf']),
        ...mapMutations('siteFuneral/addModify', ['ADD_OBJ_TO_ACTIVE_RECORD']),
        addObj(payload) {
            this.ADD_OBJ_TO_ACTIVE_RECORD(payload);
        },
        save() {
            if (this.userData && this.userData.funerals && this.userData.funerals.length == 0 && !this.vp) {
                this.submitBioFormUnassigned(this.user_id);
            } else {
                this.submitBioForm(this.vp);
            }
        },
        download() {
            this.downloadPdf(this.vp);
        },
        viewBio() {
            return this.activeRecord.role_name=='organizer' || this.vp || this.userData.funerals.length == 0;
        },
        editBio() {
            return this.activeRecord.role_name == 'organizer' || this.userData.funerals.length == 0;
        },
        fetchUnasignedBio() {
            if (this.userData && this.userData.funerals && this.userData.funerals.length == 0 && !this.vp) {
                axios.get('/dashboard/funeral/bio/fetch-unassigned-bio/' + this.user_id)
                .then(response => {
                    if (response.data.data != null) {
                        this.activeRecord.funeral_bio = response.data.data;
                    }
                })
                .catch(error => {
                    if (error.request) {
                        showNotification('There was an error when grabbing data', 'error');
                    }
                    console.error(error);
                });
            }
        },
    },
    mounted() {
        if (!this.vp || this.activeRecord.role_name == 'organizer') {
            this.selectFuneral(this.$route.params.id);
        }
        this.user_id = this.userData && this.userData.id ? this.userData.id : null;
        
        this.fetchUnasignedBio();
    },
    created() {
        this.vp = window.location.href.search('dashboard') < 0;
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.bio-form {
    display: flex;
    flex-direction: column;

    &.vp {
        overflow: hidden;

        &::v-deep {
            .input-grid {
                margin: 0;
            }
        }
    }

    .top-bar {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        margin: -.5rem -.5rem 1.5rem;
        flex-shrink: 0;

        > * {
            margin: .5rem;
        }

        @include not-desktop {
            flex-wrap: wrap;

            > button {
                flex-grow: 1;
            }
        }

        > div:first-child {
            flex-grow: 1;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        margin: -.5rem;

        > * {
            margin: .5rem;
        }
    }

    &::v-deep {
        .sections {
            display: flex;
            flex-direction: column;
        }

        h5 {
            color: var(--primary);
            margin-bottom: 0;
        }

        .input-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
            grid-auto-rows: max-content;
            margin: -.5rem;

            > * {
                margin: .5rem;
            }
        }

        .float-wrapper label {
            font-size: 12px;
            top: 0;
            left: 0.25rem;
            opacity: 1;
            background-color: #fff;
            transform: translateY(-50%);
        }
    }
}
</style>
