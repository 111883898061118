<template>
	<div
		class="simple-table-wrapper simple-table-wrapper-lg"
		v-if="!unassignedBio.isLoading"
	>
		<form
			class="search-form mb-5"
			@submit.prevent="unassignedBio.searchModel = searchModel"
		>
			<div class="search-fields d-flex flex-wrap align-items-center">
				<input
					class="st-search w-100 w-md-auto"
					type="search"
					v-model="searchModel"
					placeholder="Search..."
				/>
				<button
					type="submit"
					class="btn btn-primary ml-2"
				><span class="fas fa-search"></span> APPLY FILTERS</button>
			</div>
		</form>

		<div class="st-hidden-sorts">
			<p
				@click="unassignedBio.sortBy('decedent')"
				:class="unassignedBio.sortClass('decedent')"
			>Decedent</p>

            <p
				@click="unassignedBio.sortBy('user_name')"
				:class="unassignedBio.sortClass('user_name')"
			>User</p>

			<p
				@click="unassignedBio.sortBy('created_at', null, 'date')"
				:class="unassignedBio.sortClass('created_at')"
			>Created</p>

			<p
				@click="unassignedBio.sortBy('updated_at', null, 'date')"
				:class="unassignedBio.sortClass('updated_at')"
			>Updated</p>
		</div>

		<div
			class="simple-table"
			style="--cols:5"
		>
			<p
				class="first-col st-header"
				@click="unassignedBio.sortBy('decedent')"
				:class="unassignedBio.sortClass('decedent')"
			>Decedent</p>
            <p
				class="st-header"
				@click="unassignedBio.sortBy('user_name')"
				:class="unassignedBio.sortClass('user_name')"
			>User</p>
			<p
				class="st-header"
				@click="unassignedBio.sortBy('created_at', null, 'date')"
				:class="unassignedBio.sortClass('created_at')"
			>Created</p>
			<p
				class="st-header"
				@click="unassignedBio.sortBy('updatedd_at', null, 'date')"
				:class="unassignedBio.sortClass('updated_at')"
			>Updated</p>
			<p class="last-col st-header">Options</p>
			<template v-for="row in unassignedBio.displayedList">

				<div
					class="first-col"
					:key="row.id+'decedent'"
				>
					<span class="st-hidden">Decedent</span>
					{{ row.decedent }}
				</div>
				<p :key="row.id+'user_name'">
					<span class="st-hidden">User</span>
					{{ row.user_name }}
				</p>
				<div :key="row.id+'created_at'">
					<span class="st-hidden">Created</span>
					{{ row.created_at }}
				</div>
				<div :key="row.id+'created_at'">
					<span class="st-hidden">Updated</span>
					{{ row.updated_at }}
				</div>

				<div :key="row.id+'options'">
					<span class="st-hidden">Options</span>
					<div class="st-buttons">
						<button
							type="button"
							class="btn btn-secondary"
							@click.prevent="modifyUnassignedBio(row)"
						>Modify Bio</button>
						<button
							type="button"
							class="btn btn-outline-primary"
							@click.prevent="recycleUnassignedBio(row)"
						>Delete</button>

					</div>
				</div>
			</template>
		</div>

		<p>{{ unassignedBio.rangeText }}</p>
		<Pagination
			:active-page="unassignedBio.activePage"
			:item-count="unassignedBio.itemCount"
			:items-per-page="unassignedBio.itemsPerPage"
			@on-update="unassignedBio.updateActivePage($event)"
		/>
	</div>
	<VueLoader v-else />

</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import EloquentSSFP from "@j/core/global/EloquentSSFP";
import { DateTime } from "luxon";
export default {
	name: "VpanelUnassignedBioList",

	data() {
		return {
			unassignedBio: new EloquentSSFP(
				"/vpanel/modules/obituary/unassignedBio/list",
				{
					activeSorts: ["created_at"],
					sortDirs: ["desc"],
					sortTypes: ["date"],
					searchableData: ["decedent","user_name"],
					itemsPerPage: 10,
				}
			),
			searchModel: "",
		};
	},
	methods: {
		modifyUnassignedBio(row) {
			this.SET_ACTIVE_RECORD(row);
			this.setFormIsAdd(false);
            this.openModal(true);
			this.$router.push({
				name: "UnassignedBioIndex",
				params: { id: row.id, site: false },
			});
		},
		recycleUnassignedBio(row) {
			this.SET_ACTIVE_RECORD(row);
			this.openRecycleModal(null);
        },
		...mapMutations("unassignedBio/addModifyUnassignedBio",["SET_ACTIVE_RECORD"]),
		...mapActions("unassignedBio/addModifyUnassignedBio",["setFormIsAdd","openModal"]),
		...mapActions("unassignedBio",{openRecycleModal:"recycleUnassignedBioModal/openModal"}),
	},
	computed: {
		...mapState("unassignedBio/list", {
			cspNonce: (state) => state.cspNonce,
			isUpdatedIncrement: (state) => state.isUpdatedIncrement,
		}),
	},
	watch: {
		isUpdatedIncrement(n, o) {
			this.unassignedBio.refreshData(this.unassignedBio.options);
		},
		searchModel(n, o) {
			if (!n && o) {
				this.unassignedBio.updateSearch(n);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import "../../../../../Core/Global/Components/VueTable/scss/_table-styles.scss";

.simple-table {
	// Custom col structure to make the last col only be as wide as it needs to be
	--custom-col-structure: repeat(4, auto) max-content;
}
</style>
