<template>
    <div class="bio-form flex-grow-1" :class="{'vp': vp}">
        <div class="top-bar">
            <BioNav />
        </div>

        <form
            novalidate
            id="add-modify-funeral-form"
            :class="vp ? 'm-0 overflow-hidden h-100' : ''"
        >
            <div :class="vp ? 'flex-grow-1 overflow-auto' : ''">
                <div
                    v-show="errorMessage"
                    v-html="errorMessage"
                    class="alert alert-danger form-errors alert-dismissible"
                ></div>

                <div
                    v-show="successMessage"
                    class="alert alert-success alert-dismissible"
                    v-html="successMessage"
                ></div>

                <BiographyForm />
            </div>

        </form>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    name: 'BioIndex',
    data() {
        return {
            vp: true,
            id: 0,
        };
    },
    computed: {
        // Required for the shared funeral bio screen
        ...mapState('siteFuneral/addModify', [
            'activeRecord',
            'isFormProcessing',
            'errorMessage',
            'successMessage',
        ]),
        ...mapState(['funerals']),
    },
    methods: {
        fetchUnasignedBio() {
            axios.get('/vpanel/modules/obituary/unassignedBio/' + window.location.href.split('/')[7] + '/data')
            .then(response => {
                if (response.data.data != null) {
                    this.activeRecord.funeral_bio = response.data.data;
                }
            })
            .catch(error => {
                if (error.request) {
                    showNotification('There was an error when grabbing data', 'error');
                }
                console.error(error);
            });
        },
    },
    mounted() {
        this.fetchUnasignedBio();
    },
    created() {

    },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../sass/_vue-import.scss";

.bio-form {
    display: flex;
    flex-direction: column;

    &.vp {
        overflow: hidden;

        &::v-deep {
            .input-grid {
                margin: 0;
            }
        }
    }

    .top-bar {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        margin: -.5rem -.5rem 1.5rem;
        flex-shrink: 0;

        > * {
            margin: .5rem;
        }

        @include not-desktop {
            flex-wrap: wrap;

            > button {
                flex-grow: 1;
            }
        }

        > div:first-child {
            flex-grow: 1;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        margin: -.5rem;

        > * {
            margin: .5rem;
        }
    }

    &::v-deep {
        .sections {
            display: flex;
            flex-direction: column;
        }

        h5 {
            color: var(--primary);
            margin-bottom: 0;
        }

        .input-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
            grid-auto-rows: max-content;
            margin: -.5rem;

            > * {
                margin: .5rem;
            }
        }

        .float-wrapper label {
            font-size: 12px;
            top: 0;
            left: 0.25rem;
            opacity: 1;
            background-color: #fff;
            transform: translateY(-50%);
        }
    }
}
</style>
