import { render, staticRenderFns } from "./UnassignedBioIndex.vue?vue&type=template&id=2f3202df&scoped=true&"
import script from "./UnassignedBioIndex.vue?vue&type=script&lang=js&"
export * from "./UnassignedBioIndex.vue?vue&type=script&lang=js&"
import style0 from "./UnassignedBioIndex.vue?vue&type=style&index=0&id=2f3202df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f3202df",
  null
  
)

export default component.exports