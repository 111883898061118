<template>
    <div class="datatable-section p-2">
        <vpanel-funeral-list />
        <recycle-funeral-modal />
        <AddModifyFuneralUserModal />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BioIndex from '../../../../Portal/Custom/Site/views/BioIndex.vue';
import AddModifyFuneralUserModal from '../../../../Portal/Custom/Site/components/modals/AddModifyFuneralUserModal.vue';
export default {
    components: { BioIndex, AddModifyFuneralUserModal},
    name: "ObituaryFuneralIndex",
    created() {
        this.fetchBranches();
    },
    computed: {
        ...mapState("funeral/list", {
            isLoading: (state) => state.isLoading,
        }),
        ...mapState([
        'userData',
        ]),
    },
    methods: {
        ...mapActions("obituary", {
            fetchBranches: "list/fetchBranches",
        }),
    },
};
</script>
