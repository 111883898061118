<template>
	<div class="vp-tab-nav">

		<router-link
			:to="{ name: 'ObituaryList' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the obituary page"
			exact
        >Obituaries</router-link>

		<router-link
			:to="{ name: 'Funerals' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the funeral page"
			exact
        >Funerals</router-link>

		<router-link
			:to="{ name: 'Condolences' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the condolences page"
			exact
        >Condolences</router-link>
		<router-link
			:to="{ name: 'Schedules' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the schedules page"
			exact
        >Events</router-link>
		<router-link
			:to="{ name: 'UnassignedBio' }"
			class="nav-link"
			active-class="current"
			title="Internal link that opens the Unassigned BIOs page"
			exact
        >Unassigned BIOs</router-link>
	</div>
</template>

<script>
export default {
	name: "ObituarySecondaryNav",
};
</script>