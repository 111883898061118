<template>
    <div class="m-0 wrap-gap flex-column">
        <div class="link-group" :class="{...currentCheckName(['FuneralsIndex', 'FuneralIndex']), expanded: funeralsExpanded}">
            <div class="funerals-btn-wrapper">
                <RouterLink
                    :to="{ name: 'FuneralsIndex' }"
                    class="app-nav-btn funerals-btn"
                    active-class="current"
                    title="Internal link that opens the funeral page"
                    exact
                >
                    <div class="icon-group">
                        <div class="icon"><span class="fas fa-fw fa-portrait"></span></div>
                        Funerals
                    </div>
                </RouterLink>
                <button
                    class="funeral-expand-btn"
                    @click.stop="funeralsExpanded = !funeralsExpanded"
                    v-if="hasShownFunerals"
                ><span class="fas fa-chevron-down"></span></button>
            </div>
            <div class="link-group-children" v-if="hasShownFunerals">
                <div
                    class="link-group"
                    :class="{...currentCheckPath(`/dashboard/funeral/${id}`), 'd-none': funeral.is_hidden}"
                    v-for="(funeral, id) in funerals"
                    :key="id"
                >
                    <RouterLink
                        :to="`/dashboard/funeral/${id}`"
                        @click="selectFuneral(id)"
                        class="app-nav-btn"
                        active-class="current"
                        title="Internal link that opens to a specific funeral"
                        exact
                    >
                        {{ funeral.name }}
                    </RouterLink>
                </div>
            </div>
        </div>

        <div class="link-group" :class="currentCheckName(['CommunicationsHub'])">
            <RouterLink
                :to="{ name: 'CommunicationsHub' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the communications hub page"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-comments"></span></div>
                    Communications Hub
                </div>
            </RouterLink>
        </div>
        <div class="link-group" :class="currentCheckName(['DashboardBioIndex'])" v-show="funerals.length == 0">
            <RouterLink
                :to="{ name: 'DashboardBioIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens the biographical information for this funeral"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-book"></span></div>
                    Biographical Info
                </div>
            </RouterLink>
        </div>

        <div class="link-group" :class="currentCheckName(['ProfileIndex'])">
            <RouterLink
                :to="{ name: 'ProfileIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens your profile page"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-user-circle"></span></div>
                    Profile
                </div>
            </RouterLink>
        </div>

        <div class="link-group" :class="currentCheckName(['OrdersIndex'])">
            <RouterLink
                :to="{ name: 'OrdersIndex' }"
                class="app-nav-btn"
                active-class="current"
                title="Internal link that opens your orders"
                exact
            >
                <div class="icon-group">
                    <div class="icon"><span class="fas fa-fw fa-receipt"></span></div>
                    Orders
                </div>
            </RouterLink>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "DashboardNavItems",
    props: ['inFunerals'],
    data() {
        return {
            funeralsExpanded: false
        }
    },
    computed: {
        ...mapState([
            'isLoading',
            'funerals'
        ]),
        hasShownFunerals() {
            return Object.values(this.funerals).some(f=>!f.is_hidden);
        }
    },
    methods: {
        currentCheckName(check) {
            return { current: this.$route.matched.some(({name}) => check.includes(name)) && !this.isLoading };
        },
        currentCheckPath(check) {
            return { current: (this.$route.fullPath === check || this.$route.fullPath.includes(`${check}/`)) && !this.isLoading };
        }
    },
    watch: {
        inFunerals(n) {
            this.funeralsExpanded = n;
        }
    }
}
</script>

<style>

</style>